import React, { useState } from 'react';

function HtmlEditor() {
  const [convertedHtml, setConvertedHtml] = useState('');
  const [downloadLink, setDownloadLink] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const htmlContent = e.target.result;
      setIsLoading(true);
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlContent, 'text/html');
      const elements = doc.querySelectorAll('*');
      elements.forEach((el) => {
        // Replace Bootstrap classes with Tailwind classes
        const classNames = el.getAttribute('class');
        if (classNames) {
          const updatedClassNames = classNames
            .replace(/btn(-\w+)?/g, 'inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500')
            .replace(/form-control/g, 'block w-full px-3 py-2 border-gray-300 focus:outline-none focus:border-blue-500 rounded-md')
            .replace(/btn(-\w+)?/g, 'inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500')
            .replace(/form-control/g, 'block w-full px-3 py-2 border-gray-300 focus:outline-none focus:border-blue-500 rounded-md')
            .replace(/form-check-input/g, 'h-4 w-4 text-blue-600 border-gray-300 rounded')
            .replace(/form-check-label/g, 'ml-3 text-sm text-gray-600')
            .replace(/form-check-inline/g, 'inline-flex items-center mr-6')
            .replace(/input-group-text/g, 'px-3 py-2 rounded-l-md rounded-r-none border border-gray-300 bg-gray-50 text-gray-500')
            .replace(/input-group-(prepend|append)/g, 'flex items-center px-3 rounded-l-md border border-gray-300 bg-gray-50 text-gray-500')
            .replace(/custom-select/g, 'block w-full px-3 py-2 rounded-md border-gray-300 focus:outline-none focus:border-blue-500')
            .replace(/navbar(-\w+)?/g, 'relative flex flex-wrap items-center justify-between px-2 py-3 bg-gray-800')
            .replace(/nav-(link|item)/g, 'block px-3 py-2 rounded-md text-base font-medium text-white hover:text-white hover:bg-gray-700')
            .replace(/card/g, 'bg-white overflow-hidden shadow-sm sm:rounded-lg')
            .replace(/card-header/g, 'px-4 py-3 bg-gray-50 border-b border-gray-200')
            .replace(/card-title/g, 'text-lg font-medium text-gray-900')
            .replace(/card-body/g, 'p-4')
            .replace(/alert(-\w+)?/g, 'rounded-md p-4 bg-blue-50 text-blue-800')
            .replace(/badge(-\w+)?/g, 'inline-flex items-center px-2 rounded-md font-semibold text-white bg-blue-500')
            .replace(/btn(-\w+)?/g, 'inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500')
            .replace(/card/g, 'bg-white overflow-hidden shadow-sm sm:rounded-lg')
            .replace(/card-header/g, 'px-4 py-3 bg-gray-50 border-b border-gray-200')
            .replace(/card-title/g, 'text-lg font-medium text-gray-900')
            .replace(/card-body/g, 'p-4')
            .replace(/form-control/g, 'block w-full px-3 py-2 border-gray-300 focus:outline-none focus:border-blue-500 rounded-md')
            .replace(/form-check/g, 'mt-3')
            .replace(/form-check-input/g, 'h-4 w-4 text-blue-600 border-gray-300 rounded')
            .replace(/form-check-label/g, 'ml-3 text-sm text-gray-600')
            .replace(/form-check-inline/g, 'inline-flex items-center mr-6')
            .replace(/form-floating/g, 'mb-3')
            .replace(/form-label/g, 'block text-gray-700 text-sm font-bold mb-2')
            .replace(/input-group-text/g, 'px-3 py-2 rounded-l-md rounded-r-none border border-gray-300 bg-gray-50 text-gray-500')
            .replace(/input-group-(prepend|append)/g, 'flex items-center px-3 rounded-l-md border border-gray-300 bg-gray-50 text-gray-500')
            .replace(/input-group/g, 'mb-3')
            .replace(/navbar(-\w+)?/g, 'relative flex flex-wrap items-center justify-between px-2 py-3 bg-gray-800')
            .replace(/nav-(link|item)/g, 'block px-3 py-2 rounded-md text-base font-medium text-white hover:text-white hover:bg-gray-700')
            .replace(/pagination(-\w+)?/g, 'inline-flex space-x-2')
            .replace(/page-link/g, 'px-3 py-2 rounded-md text-blue-600 bg-white border-gray-300 hover:bg-blue-50')
            .replace(/page-item/g, '')
            .replace(/table/g, 'min-w-full divide-y divide-gray-200')
            .replace(/thead/g, 'bg-gray-50')
            .replace(/tbody/g, 'bg-white divide-y divide-gray-200')
            .replace(/th/g, 'px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider')
            .replace(/tr/g, '')
            .replace(/td/g, 'px-6 py-4 whitespace-nowrap')
            .replace(/table-striped/g, 'bg-gray-50')
            .replace(/table-bordered/g, 'border')
            .replace(/table-hover/g, 'hover:bg-gray-100')
            .replace(/thead-dark/g, 'bg-dark text-white')
            .replace(/thead-light/g, 'bg-gray-50')
            .replace(/bg-primary/g, 'bg-blue-600')
            .replace(/bg-secondary/g, 'bg-gray-600')
            .replace(/bg-success/g, 'bg-green-500')
            .replace(/bg-danger/g, 'bg-red-500')
            .replace(/bg-warning/g, 'bg-yellow-500')
            .replace(/bg-info/g, 'bg-blue-500')
            .replace(/text-primary/g, 'text-blue-600')
            .replace(/text-secondary/g, 'text-gray-600')
            .replace(/text-success/g, 'text-green-500')
            .replace(/text-danger/g, 'text-red-500')
            .replace(/text-warning/g, 'text-yellow-500')
            .replace(/text-info/g, 'text-blue-500')
            .replace(/bg-light/g, 'bg-gray-100')
            .replace(/bg-dark/g, 'bg-gray-800')
            .replace(/text-white/g, 'text-gray-50')
            .replace(/text-muted/g, 'text-gray-400')
            .replace(/text-center/g, 'text-center')
            .replace(/text-left/g, 'text-left')
            .replace(/text-right/g, 'text-right')
            .replace(/m-auto/g, 'mx-auto')
            .replace(/mt-\d+/g, (match) => 'mt-' + (match.substring(3) * 4))
            .replace(/mb-\d+/g, (match) => 'mb-' + (match.substring(3) * 4))
            .replace(/ml-\d+/g, (match) => 'ml-' + (match.substring(3) * 4))
            .replace(/mr-\d+/g, (match) => 'mr-' + (match.substring(3) * 4))
            .replace(/p-\d+/g, (match) => 'p-' + (match.substring(2) * 2))
            .replace(/pt-\d+/g, (match) => 'pt-' + (match.substring(3) * 2))
            .replace(/pb-\d+/g, (match) => 'pb-' + (match.substring(3) * 2))
            .replace(/pl-\d+/g, (match) => 'pl-' + (match.substring(3) * 2))
            .replace(/pr-\d+/g, (match) => 'pr-' + (match.substring(3) * 2));

            // add more replacements for other bootstrap classes here
            el.setAttribute('class', updatedClassNames);
          }
        });
        const convertedHtmlContent = doc.documentElement.outerHTML;
        setConvertedHtml(convertedHtmlContent);
        const blob = new Blob([convertedHtmlContent], { type: 'text/html' });
        const url = URL.createObjectURL(blob);
        setDownloadLink(url);
        setIsLoading(false);
      };
      reader.readAsText(file);
    };
  
    const handleDownload = () => {
      const blob = new Blob([convertedHtml], { type: 'text/html' });
      const url = URL.createObjectURL(blob);
      const downloadLink = document.createElement('a');
      downloadLink.href = url;
      downloadLink.download = 'converted.html';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(url);
    };
  
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <h1 className="text-3xl font-bold mb-4">Convert</h1>
        <div className="flex items-center space-x-4 mb-4">
          <input type="file" onChange={handleFileUpload} />
          {isLoading ? (
            <div className="relative w-64 h-4 rounded-full overflow-hidden">
              <div className="absolute top-0 left-0 h-full bg-blue-500 rounded-full" style={{ width: '50%' }}></div>
            </div>
          ) : downloadLink ? (
            <button className="px-4 py-2 rounded-md bg-blue-500 text-white hover:bg-blue-600" onClick={handleDownload}>
              Download Converted File
            </button>
          ) : null}
        </div>
      </div>
    );
  }

export default HtmlEditor;
